import React from "react";
import { graphql, navigate, withPrefix } from "gatsby";
import { getUserLangKey } from "../utils/lang";

const RedirectIndex = (props) => {
  //Skip build, Browsers only
  if (typeof window !== "undefined") {
    const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
    const langKey = getUserLangKey(langs, defaultLangKey);
    const homeUrl = withPrefix(`/${langKey}/`);

    navigate(homeUrl);
  }

  return <div>loading ...</div>;
};

export default RedirectIndex;


export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
